.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 10px;
}

.table-header {
    background-color: #672d2d;
    /* Set your desired color here */
}

.c-row {
    display: flex;
    flex-direction: row;
    height: 20px;
    padding: 0px;
    margin: 0px;
    justify-content: center;
    align-items: center;

}

.fs-8 {
    font-size: 8px;
}