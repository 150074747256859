.home-page {
  text-align: center;
  margin-top: 20px;
}

.grid-menu {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.menu-item-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  text-decoration: none;
}

/* .menu-item-title {
  font-size: 20px;
  font-weight: bold;
  a {
    text-decoration: none;
  }
} */
